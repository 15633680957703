<template>
  <div>
    <el-container>
      <tree-layout treeTitle="组织架构" :tree-options="treeOptions" @tree-node-click="treeNodeClick"
                   @include-down="includeDown" style="margin-right:5px;"></tree-layout>
      <el-container>
        <el-main>
          <grid-head-layout
            ref="gridHeadLayout"
            :grid-head-btn="gridHeadBtn"
            :search-columns="leftSearchColumns"
            :deviceProtocol="deviceProtocol"
            @grid-head-search="leftGridHeadSearch"
            @grid-head-empty="leftGridHeadEmpty"
          ></grid-head-layout>
          <grid-layout
            class="deparmentLayout"
            ref="leftGridLayOut"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="leftTableData"
            :table-loading="leftTableLoading"
            :data-total="leftPage.total"
            :page="leftPage"
            @page-current-change="leftOnLoad"
            @page-size-change="leftOnLoad"
            @page-refresh-change="leftOnLoad"
          >
          </grid-layout>
        </el-main>
        <div class="btn">
          <el-button  style="width: 40px;height: 40px;align-items: center;" type="primary"  icon="el-icon-arrow-left"  :disabled="false"  :loading="false" circle @click="leftRemove"></el-button>
          <el-button   style="width: 40px;height: 40px;align-items: center;"  type="primary" icon="el-icon-arrow-right" :disabled="false"  :loading="false" circle @click="rightAdd"></el-button>
        </div>
        <el-main>
          <div style="height: 56px;">

          </div>
          <grid-layout
            ref="rightGridLayOut"
            class="deparmentLayout"
            :grid-row-btn="gridRowBtn"
            :table-options="tableOptions"
            :table-data="rightTableData"
            :table-loading="rightTableLoading"
          >
          </grid-layout>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

import {getDeptLazyTree} from "@/api/system/dept";
import
{
  pageDeptByRole,
  roleAllocation,
  addDeptByRole,
  removeDeptByRole,
} from "@/api/system/user";
import {getDeptTree} from "@/api/system/dept";
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import {mapGetters} from "vuex";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import TreeLayout from "@/views/components/layout/tree-layout";

export default {
  name: "protocol",
  components: {
    GridLayout,
    HeadLayout,
    TreeLayout
  },
  props: {
    userList:{}
  },
  data() {
    return {
      leftTableLoading: true,
      rightTableLoading: false,
      leftTableData: [],
      rightTableData: [],
      deviceProtocol: this.verdict,
      leftQuery:{},
      rightQuery:{},
      query:{},
      getDeptTreeDicData:[],
      leftPage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      rightPage: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      treeOptions: {
        defaultExpandAll: false,
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentId = (node.level === 0) ? 0 : node.data.id;
          getDeptLazyTree(parentId).then(res => {
            resolve(res.data.data.map(item => {
              return {
                ...item,
                leaf: !item.hasChildren
              }
            }))
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: 'title',
          value: 'value',
          children: 'children'
        }
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    leftSearchColumns() {
      return [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
          prop: "account",
          cell: true,
          align: 'left',
          width: 150,
          overHidden: true,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") +  this.$t("cip.plat.sys.user.field.realName"),
          prop: "realName",
          cell: true,
          align: 'left',
          width: 150,
          overHidden: true,
        },
      ]
    },
    reghtSearchColumns() {
      return [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
          prop: "account",
          cell: true,
          align: 'left',
          width: 150,
          overHidden: true,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") +  this.$t("cip.plat.sys.user.field.realName"),
          prop: "realName",
          cell: true,
          align: 'left',
          width: 150,
          overHidden: true,
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        menu: false,
        selection: true,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
            align: 'left',
            width: 150,
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
            width: 150,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.roleName"),
            prop: "deptName",
            cell: true,
            align: 'left',
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      return []
    }
  },
  gridHeadBtn() {
    return []
  },
  headBtnOptions() {
  },
  mounted() {
    console.log("进行输出----------",this.userList)
    getDeptTree("000000").then(res => {
      this.getDeptTreeDicData = res.data.data;
      this.leftOnLoad(this.leftPage)
      // this.rightOnLoad(this.rightPage, {})
    });
  },
  methods: {

    /**
     * 向右边增加
     */
    rightAdd(){
      let selectionList = this.$refs.leftGridLayOut.selectionList;
      console.log(selectionList,'228')
      let idsToDelete = this.rightTableData.map(item => (item.deptId+item.id));
      let filteredList = selectionList.filter(item => !idsToDelete.includes(item.deptId+item.id));
      filteredList.forEach((item)=>{
        console.log(item,'233')
        this.rightTableData.push({
          account:item.account,
          realName:item.realName,
          deptName:item.deptName,
          id:item.id,
          deptId:item.deptId,
          roleId:item.roleId,
          isDefaultDept:item.isDefaultDept,
        });
      })
    },
    submitDepartment(){
      return this.rightTableData
    },
    /**
     * 向左边删除
     */
    leftRemove(){
      let selectionList = this.$refs.rightGridLayOut.selectionList
      let idsToDelete = selectionList.map(item => (item.deptId+item.id));
      let filteredList = this.rightTableData.filter(item => !idsToDelete.includes(item.deptId+item.id));
      this.rightTableData = []
      filteredList.forEach((item)=>{
        this.rightTableData.push(item)

      })
    },
    includeDown(e) {
      this.leftPage.currentPage = 1;
      this.query.isInclude = e;
      this.leftOnLoad(this.leftPage);
    },
    /**
     * 部门树结构点击事件
     * @param node
     */
    treeNodeClick(node) {
      this.treeDeptId = node.id;
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage);
    },
    leftGridHeadSearch(query) {
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage, query);
    },
    leftGridHeadEmpty(query) {
      this.leftPage.currentPage = 1;
      this.leftOnLoad(this.leftPage, query);
    },
    leftOnLoad(page, params = {}) {
      this.param = params;
      this.leftQuery = params
      this.leftQuery.accountList= this.userList.map(e=>e.account);
      this.leftPage = page;
      this.leftTableLoading = true;
      pageDeptByRole(page.currentPage, page.pageSize, Object.assign(params, this.leftQuery), this.treeDeptId).then(res => {
        const data = res.data.data;
        // this.$refs.leftGridLayOut.leftPage.total = data.total;
        data.records.forEach(e=>{
          e.deptName = this.findChildData(this.getDeptTreeDicData,e.deptId).title
        })
        this.leftPage.total = data.total;
        this.leftTableData = data.records;
        this.leftTableLoading = false;
      });
    },

    findChildData(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === childId) {
          // 如果当前节点就是目标子节点，直接返回其数据
          return node;
        } else if (node.children) {
          // 否则递归查找当前节点的子节点
          const childData = this.findChildData(node.children, childId);
          if (childData) {
            // 如果找到了目标子节点的数据，返回该数据
            return childData;
          }
        }
      }
      // 没有找到目标子节点的数据，返回 null
      return null;
    },

  }
};
</script>
<style scoped lang="scss">
.btn{
  display: flex;
  align-items: center;
}
::v-deep .deparmentLayout .avue-crud .el-table {
  height: calc(100vh - 324px) !important;
  max-height: calc(100vh - 324px) !important;
}

::v-deep .el-tree {
  height: calc(100vh - 333px) !important;
}
</style>
