<template>
  <div style="padding: 0 20px;">
    <el-table
      height="380"
      :data="tableData"
      ref="table"
      style="width: 100%"
      size="medium"
    >
      <el-table-column
        prop="department"
        :fixed="isFixed"
        align="center"
        label="部门名称">
      </el-table-column>
      <el-table-column
        prop="account"
        :fixed="isFixed"
        align="center"
        label="用户编码">
      </el-table-column>
      <el-table-column
        prop="realName"
        :fixed="isFixed"
        align="center"
        label="用户名称">
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="superiorDepartment"-->
      <!--        :fixed="true"-->
      <!--        label="上级部门">-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--        prop="isDefaultDeptChecked"-->
      <!--        :fixed="isFixed"-->
      <!--        align="center"-->
      <!--        width="100px"-->
      <!--        label="是否主组织">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-checkbox v-model="scope.row.isDefaultDeptChecked"-->
      <!--                       @change="superiorDepartmentCheckData(scope)"></el-checkbox>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        v-for="(item,index) in userListData" :key="index"
        :label="item.roleName"
        align="left"
        width="35px"
      >
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row['roleCheck' + index]"
                       @change="checkData(scope,item)"></el-checkbox>
        </template>
      </el-table-column>

    </el-table>

  </div>
</template>

<script>
import {getList as userList, roleGetList} from "@/api/system/user";
import Template from "@/views/message/template/list";
import {
  add, classificationRoleGetList,
  classificationRoleSubmit,
  classificationRoleRemove,
  getList,
  getRole,
  getRoleTreeById,
  grant,
  grantTree,
  remove,
  update
} from "@/api/system/role";

export default {
  components: {Template},
  props: {
    // deptList: {
    //   type: Array,
    //   default: [],
    // },
    userFrom: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: "",
    },
    isFixed: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 定义矩阵大小为3x4
      rows: 3,
      columns: 4,
      userListData: [],
      tableData: [],
      checked: false,
      superiorDepartment: false,
      // 初始化所有单元格未被选中状态
      selectedCells: Array.from({length: this.rows}, () => new Array(this.columns).fill(false)),
      submitList: [],
      depart: [],
      departmentIdList: [],
      userFrom: {},
      deptList: [],
    };
  },
  mounted() {
    // this.getDataList()
  },
  methods: {
    getDataList() {
      console.log("进行输出----------")
      if (this.type == "roleConfigBatchDialog") {
        classificationRoleGetList(1, 99999).then((res) => {
          if (res.data.code == 200) {
            this.userListData = res.data.data.records;
            this.$nextTick(() => {
              this.$refs.table.doLayout();
            })
          }
        })
      } else {
        roleGetList(1, 99999).then((res) => {
          if (res.data.code == 200) {
            this.userListData = res.data.data.records;
            this.$nextTick(() => {
              this.$refs.table.doLayout();
            })
          }
          this.updateDeptList();
        })
      }
    },
    getDeptList() {
      this.$refs.table.tableData = [];
      this.tableData = [];
      this.departmentIdList = []
      console.log(this.deptList, '78------------------------------')
      let cc = []
      let departmentIdList = this.assembleData(this.submitList, 'departmentId');
      let departmentIds = this.submitList.map(item => item.departmentId);
      this.deptList.forEach(e => {
        if (departmentIds.indexOf(e.departmentId) > -1) {
          this.tableData.push(departmentIdList[e.departmentId][0])
          cc.push(departmentIdList[e.departmentId][0]);
        } else {
          e.roleId = [];
          this.$set(e, 'isDefaultDeptChecked', false)
          this.userListData.forEach((item, index) => {
            let checked = 'roleCheck' + index
            this.$set(e, checked, false)
          })
          cc.push(e);
          this.tableData.push(e)
        }

      })
      this.submitList = cc
      this.submitList.forEach((e) => {
        if (e.isDefaultDept == "1") {
          this.departmentIdList.push(e.departmentId)
        }
      })
    },
    assembleData(list, property) {
      return list.reduce((acc, obj) => {
        let key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    },
    updateDeptList() {
      this.$refs.table.tableData = [];
      this.tableData = [];
      this.submitList = [];
      this.departmentIdList = []
      console.log(this.deptList, '799------------------------------')
      console.log(this.userListData, '899------------------------------')
      this.deptList.forEach(e => {
        // if (e.isDefaultDept == '1') {
        //   this.$set(e, 'isDefaultDeptChecked', true)
        // } else {
        //   this.$set(e, 'isDefaultDeptChecked', false)
        // }
        this.userListData.forEach((item, index) => {
          let checked = 'roleCheck' + index
          this.$set(e, checked, false)
          e.roleId.forEach(role => {
            if (item.id == role) {
              this.$set(e, checked, true)
            }
          })
        })
        this.tableData.push(e)
        this.submitList.push(e)
        // if (e.isDefaultDept == "1") {
        //   this.departmentIdList.push(e.departmentId)
        // }
      })
    },
    submit() {
      let roleIds = []
      console.log("进行输出this.submitList123-----------",this.submitList)
      this.submitList.forEach((item) => {
        var deptAncestor = item.deptAncestor;
        let cc = {
          deptId: item.departmentId,
          id: item.userId,
          isDefaultDept: item.isDefaultDept,
          deptAncestor: deptAncestor.toString(),
          roleId: item.roleId.toString(),
          userId: item.userId,
        }
        roleIds.push(cc)
      })
      //进行输出构造并进行传参
      // this.$emit("submitList", roleIds);
      let isMainList = []
      roleIds.forEach((item) => {
        if (item.roleId == "") {
          this.$message({
            type: "error",
            message: this.$t("cip.cmn.msg.success.selectARole")
          });
          throw new Error(this.$t("cip.cmn.msg.success.selectARole"))

        }
        isMainList.push(item.isDefaultDept)
      })
      //因为保存并返回的是String类型的修改选择的是int类型的故此使用此方法
      // let primaryOrganization = true
      // isMainList.forEach((item) => {
      //   if (item == "1") {
      //     primaryOrganization = false
      //   }
      // })
      // if (primaryOrganization) {
      //   this.$message({
      //     type: "error",
      //     message: this.$t("cip.cmn.msg.success.isDefaultDept")
      //   });
      //   throw new Error(this.$t("cip.cmn.msg.success.isDefaultDept"))
      // }
      return roleIds;
    },

    superiorDepartmentCheckData(scope) {
      console.log("scopescope", scope)
      var departmentId = scope.row.departmentId;
      if (this.departmentIdList.includes(departmentId)) {
        const newList = this.departmentIdList.filter((e) => {
          return e !== departmentId;
        })
        this.departmentIdList = newList
      } else {
        this.departmentIdList.push(departmentId)
      }

      if (this.departmentIdList.length > 1) {
        return this.$message({
          type: "error",
          message: this.$t("cip.cmn.msg.success.deptError")
        });
      }
      //是否是默认部门
      this.submitList.forEach(e => {
        var row = scope.row;
        e.isDefaultDept = 0
        if (e.departmentId == this.departmentIdList[0]) {
          e.isDefaultDept = 1
        }
      })
    },
    checkData(scope, item) {
      //查询是否是取消勾选的
      if (scope.row.roleId.includes(item.id)) {
        const roleId = scope.row.roleId.filter((e) => {
          return e !== item.id;
        })
        scope.row.roleId = roleId
      } else {
        console.log("进行输出-----------",scope.row)
        scope.row.roleId.push(item.id)
      }
      //将List转换成以departmentId为的键值对
      const map = this.submitList.reduce((acc, item) => {
        acc[item.departmentId] = item;
        return acc;
      }, {});
      //获取部门id
      var departmentId = scope.row.departmentId;
      var mapElement = map[departmentId];
      if (typeof mapElement == "undefined") {
        this.submitList.push(scope.row)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.bottonmBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
::v-deep .el-table .el-table__cell.is-center{
  vertical-align: top !important;
}
::v-deep .el-table th.el-table__cell.is-leaf{
  vertical-align: top !important;
}
</style>
